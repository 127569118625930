import { useDebouncedEffect } from "@react-hookz/web";
import { isEqual } from "lodash";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";

import ApplicationLayout from "../../components/layouts/Application";
import PasswordModal from "../../components/layouts/partials/PasswordModal";
import type { Product } from "../../data/models/partials/Product";
import { useApiData } from "../../use/api";
import { useAuthentication } from "../AuthenticationProvider";
import { useNotifications } from "../NotificationsProvider";
import { getCurrentProduct, SidebarItem, usePrimaryNavigation, useSecondaryNavigation } from "./application/navigation";
import useTopBar from "./application/top-bar";

type ApplicationLayoutOptions = Pick<
  React.ComponentProps<typeof ApplicationLayout>,
  "hiddenSidebar" | "fixedHeight" | "currentTitle"
> & {
  currentRoute?: SidebarItem["id"];
};

const ApplicationLayoutContext = createContext({
  configureLayout: (options: ApplicationLayoutOptions) => {},
  currentProduct: undefined as Product | undefined,
});

export function useApplicationLayout() {
  return useContext(ApplicationLayoutContext);
}

export default function ApplicationLayoutProvider({ children }: { children: React.ReactNode }) {
  const [applicationLayoutOptions, setApplicationLayoutOptions] = useState<ApplicationLayoutOptions>({});
  const [applicationLayoutOptionsDebounced, setApplicationLayoutOptionsDebounced] = useState<ApplicationLayoutOptions>(
    {},
  );

  const { user, logout, teams, teamMemberships, promptForPassword, adminRoles } = useAuthentication();
  const notifications = useNotifications();
  const location = useLocation();

  const route = useMatch(location.pathname);

  const configureApplicationLayout = useCallback((options: Partial<ApplicationLayoutOptions>) => {
    setTimeout(() => {
      // Cannot be called synchronously, otherwise it will cause a re-render loop
      setApplicationLayoutOptions((previousOptions) => {
        if (isEqual({ ...previousOptions, ...options }, previousOptions)) return previousOptions;

        return { ...previousOptions, ...options };
      });
    });
  }, []);

  useEffect(() => {
    setApplicationLayoutOptions({});
  }, [route]);

  useDebouncedEffect(
    () => {
      setApplicationLayoutOptionsDebounced(applicationLayoutOptions);
    },
    [applicationLayoutOptions],
    200,
  );

  const currentTeamId = useMemo(() => {
    return teams?.find((t) => location.pathname.split("/").includes(t.domain))?.id;
  }, [teams, location.pathname]);

  const currentProduct = useMemo(() => {
    return getCurrentProduct({
      teams: teams!,
      currentTeamId: currentTeamId,
      hostName: window.location.host,
    });
  }, [teams, currentTeamId]);

  const { game } = useApiData("global.gamification.show");

  const topBar = useTopBar({
    currentProduct: currentProduct ?? null,
    currentRoute: applicationLayoutOptionsDebounced.currentRoute ?? null,
  });

  const primaryNavigationItems = usePrimaryNavigation({
    isAdmin: user!.isAdmin,
    teams: teams!,
    teamMemberships: teamMemberships!,
    currentTeamId: currentTeamId,
    currentProduct: currentProduct,
    currentRoute: applicationLayoutOptionsDebounced.currentRoute,
    adminRoles,
  });

  const secondaryNavigationItems = useSecondaryNavigation({
    teams: teams!,
    currentTeamId: currentTeamId,
    primaryNavigation: primaryNavigationItems,
    currentProduct,
  });

  const availableProducts = useMemo(() => {
    return new Set<Product>(
      teams!
        .map((t) => t.products)
        .flat()
        .filter((p) => p !== currentProduct),
    );
  }, [teams, currentProduct]);

  return (
    <ApplicationLayoutContext.Provider
      value={{ configureLayout: configureApplicationLayout, currentProduct: currentProduct }}
    >
      {promptForPassword && <PasswordModal />}
      <ApplicationLayout
        currentTitle={applicationLayoutOptionsDebounced.currentTitle}
        auth={{ email: user!.email }}
        primaryNavigation={{ items: primaryNavigationItems }}
        secondaryNavigation={
          secondaryNavigationItems
            ? {
                title: "Teams",
                items: secondaryNavigationItems,
              }
            : undefined
        }
        product={currentProduct}
        availableProducts={availableProducts}
        fixedHeight={applicationLayoutOptionsDebounced.fixedHeight}
        onLogout={logout}
        hiddenSidebar={applicationLayoutOptionsDebounced.hiddenSidebar}
        gamification={currentProduct === "helloscreen" ? game : null}
        notifications={notifications}
        topBar={topBar}
      >
        {children}
      </ApplicationLayout>
    </ApplicationLayoutContext.Provider>
  );
}
