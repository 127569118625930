import { GamificationCurrentGame } from "~core/gamification/userGamification";

import { Product } from "../../../../../data/models/partials/Product";
import { productName } from "../../../../../formatters/billing";
import t from "../../../../../i18n";
import ExtensionPrompt from "./Extension";
import G2Prompt from "./G2Review";
import Streak from "./Streak";

type Props = {
  currentProduct?: Product;
  game?: GamificationCurrentGame;
};

export default function Gamification({ currentProduct, game }: Props) {
  if (!game || !currentProduct || !game.allowedProducts?.includes(currentProduct)) return <ExtensionPrompt />;

  return <GameWidget game={game} currentProduct={currentProduct} />;
}

function GameWidget({ game, currentProduct }: { game: GamificationCurrentGame; currentProduct: Product }) {
  switch (game.id) {
    case "confetti_streak":
      return (
        <Streak current={game.currentStreak} goal={game.goal}>
          {t("Use %{currentProduct} every day for a week, and unlock confetti mode 🎉.", {
            currentProduct: productName(currentProduct),
          })}
        </Streak>
      );
    case "g2_review":
      return <G2Prompt reward={game.reward} product={currentProduct} />;
  }
}
