import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";

import t from "./i18n";
import logger from "./log";
import ApiCacheProvider, { useApiCache } from "./providers/ApiCacheProvider";
import AuthenticationProvider from "./providers/AuthenticationProvider";
import DarkModeProvider from "./providers/DarkModeProvider";
import { ModalProvider, useModal } from "./providers/ModalProvider";
import { PreloadProvider } from "./providers/PreloadProvider";
import { StripeProvider } from "./providers/StripeProvider";
import { ToastProvider } from "./providers/ToastProvider";
import Router from "./router";

const debug = logger("app");

export default function App() {
  useEffect(() => {
    // If reloaded page
    if (
      (window.performance?.getEntriesByType("navigation")?.[0] as PerformanceNavigationTiming | undefined)?.type ===
        "reload" &&
      sessionStorage.getItem("reload") !== "true"
    )
      debug("Detected manual reload on %s", location.href);

    sessionStorage.removeItem("reload");
  }, []);

  return (
    <ApiCacheProvider>
      <PreloadProvider>
        <DarkModeProvider>
          <ToastProvider>
            <ModalProvider>
              <ErrorBoundary FallbackComponent={FatalError}>
                <AuthenticationProvider>
                  <StripeProvider>
                    <Router />
                  </StripeProvider>
                </AuthenticationProvider>
              </ErrorBoundary>
            </ModalProvider>
          </ToastProvider>
        </DarkModeProvider>
      </PreloadProvider>
    </ApiCacheProvider>
  );
}

export function FatalError({ error }: { error: unknown }) {
  const { addModal } = useModal();
  const { newVersionAvailable } = useApiCache();

  useEffect(() => {
    if (typeof error === "undefined" || error === null) return;

    if (newVersionAvailable) {
      debug("New version available, ignoring error %o", error);
      return;
    }

    if (error instanceof Error) {
      // This happens when a new version of the app is deployed and we try to
      // load a script with an invalid hash
      if (error.message.match(/dynamically imported module/i)) return window.reload();
      if (error.message.match(/could not resolve bundle with/i)) return window.reload();
    }
    Sentry.captureException(error);
  }, [error, newVersionAvailable]);

  useEffect(() => {
    if (newVersionAvailable) {
      addModal({
        title: t("A new version is available"),
        content: t("A new version of the application is available and we need to reload the page for it to work."),
        onClick: () => window.reload(),
        confirmText: t("Reload application"),
      });
    } else {
      addModal({
        title: t("You just found a bug!"),
        content: t("It looks like you found a bug with our system. Our team has been alerted."),
        onClick: () => window.reload(),
        confirmText: t("Try again"),
      });
    }
  }, [addModal, newVersionAvailable]);

  return null;
}
