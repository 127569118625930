import { StarIcon } from "@heroicons/react/20/solid";

import { Product } from "../../../../../data/models/partials/Product";
import t from "../../../../../i18n";
import { useModal } from "../../../../../providers/ModalProvider";
import { useApi } from "../../../../../use/api";
import GamificationBox from "./GamificationBox";

export default function G2Prompt({ reward, product }: { reward: "confetti_unlock"; product: Product }) {
  const { addModal } = useModal();
  const { call: report } = useApi("global.gamification.leftReview.report");

  function onLeftReview() {
    report();

    addModal({
      title: t("Thank you for leaving a review!"),
      content: t("Thank you for leaving a review on G2. You have now unlocked confetti mode!"),
      type: "success",
      replace: true,
    });
  }

  function onClick() {
    // TODO: Change URL for different products
    window.open("https://www.g2.com/products/helloscreen-by-upscope/reviews/start");

    setTimeout(() => {
      addModal({
        title: t("Were you able to leave a review?"),
        content: t("Were you able to leave a review on G2? If you need a hand, please let us know!"),
        confirmText: t("I left a review"),
        cancelText: t("Not yet"),
        onClick(leftReview: boolean) {
          if (leftReview) onLeftReview();
        },
      });
    }, 10_000);
  }

  return (
    <GamificationBox onClick={onClick}>
      <div className="mb-2 flex items-center gap-1">
        <StarIcon className="w-4" />
        <StarIcon className="w-4" />
        <StarIcon className="w-4" />
        <StarIcon className="w-4" />
        <StarIcon className="w-4" />
      </div>
      <span>{t("Leave a quick review of HelloScreen on G2 and unlock confetti mode 🎉.")}</span>
    </GamificationBox>
  );
}
