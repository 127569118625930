import * as Sentry from "@sentry/browser";
import Cookie from "js-cookie";
import { createRoot } from "react-dom/client.js";

import App from "./app";
import { initI18n } from "./i18n";
import logger from "./log";

const errorLogger = logger("errors");

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://5657ddafdf314975895cc564eb17315f@sentry.io/157375",
    release: process.env.CURRENT_APP_VERSION,
    environment: process.env.NODE_ENV,
    maxValueLength: 2500,
    beforeSend: (event, hint) => {
      const error = hint.originalException ?? hint.syntheticException ?? event.message;
      if (error) errorLogger("Error: %s", error);
      return event;
    },
  });
}

window.reload = () => {
  sessionStorage.setItem("reload", "true");
  location.reload();
};

const language = Cookie.get("upscope_locale") ?? navigator.language;
initI18n(language).then(() => {
  const root = createRoot(document.querySelector("#root")!);

  root.render(<App />);
});
