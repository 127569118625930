import { useDebouncedEffect } from "@react-hookz/web";
import { isEqual } from "lodash";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";

import AuthLayout from "../../components/layouts/Auth";

type AuthLayoutOptions = Pick<React.ComponentProps<typeof AuthLayout>, "product">;
const AuthLayoutContext = createContext({
  configureLayout: (options: AuthLayoutOptions) => {},
});

export function useAuthLayout() {
  return useContext(AuthLayoutContext);
}

export default function AuthLayoutProvider({ children }: { children: React.ReactNode }) {
  const [authLayoutOptions, setAuthLayoutOptions] = useState<AuthLayoutOptions>({});
  const [authLayoutOptionsDebounced, setAuthLayoutOptionsDebounced] = useState<AuthLayoutOptions>({});
  const location = useLocation();
  const route = useMatch(location.pathname);

  useEffect(() => {
    setAuthLayoutOptions({});
  }, [route]);

  useDebouncedEffect(
    () => {
      setAuthLayoutOptionsDebounced(authLayoutOptions);
    },
    [authLayoutOptions],
    50,
  );

  const configureLayout = useCallback((options: AuthLayoutOptions) => {
    setTimeout(() => {
      // Cannot be called synchronously, otherwise it will cause a re-render loop
      setAuthLayoutOptions((previousOptions) => {
        if (isEqual({ ...previousOptions, ...options }, previousOptions)) return previousOptions;

        return { ...previousOptions, ...options };
      });
    });
  }, []);

  return (
    <AuthLayoutContext.Provider value={{ configureLayout }}>
      <AuthLayout {...authLayoutOptionsDebounced}>{children}</AuthLayout>
    </AuthLayoutContext.Provider>
  );
}
