import { useMemo } from "react";

import { Product } from "~data/Product";

import { ApplicationLayoutProps } from "../../../components/layouts/Application";
import { productName } from "../../../formatters/billing";
import t from "../../../i18n";
import { useAuthentication } from "../../AuthenticationProvider";
import { SidebarItem } from "./navigation";

export default function useTopBar({
  currentProduct,
  currentRoute,
}: {
  currentProduct: Product | null;
  currentRoute: SidebarItem["id"] | null;
}): ApplicationLayoutProps["topBar"] {
  const { user, teams, impersonating } = useAuthentication();

  const purchaseRequiredTeam = useMemo(() => {
    return (
      teams
        ?.filter((t) => !currentProduct || t.products.includes(currentProduct))
        .find((t) => !t.isSubaccount && !t.paying && !t.trialEndsOn && !t.trialAvailable && !t.freeAccount) || null
    );
  }, [teams, currentProduct]);

  const bannedTeam = useMemo(() => {
    return teams?.find((t) => t.banned) || null;
  }, [teams]);

  const onTrialNoCardTeam = useMemo(() => {
    return (
      teams
        ?.filter((t) => !currentProduct || t.products.includes(currentProduct))
        .find((t) => t.trialEndsOn && !t.trialConvertsAutomatically) || null
    );
  }, [teams, currentProduct]);

  const trialNoCardEndsOn = useMemo(() => {
    return onTrialNoCardTeam?.trialEndsOn || null;
  }, [onTrialNoCardTeam]);

  if (impersonating) {
    return {
      content: `You are currently impersonating ${user.name}!`,
      cta: { label: "Stop impersonating", to: ["hq.impersonation.stop"] },
    };
  }

  if (bannedTeam) {
    return {
      content: t("Our team has been trying to contact you. Please get in touch to continue using Upscope."),
      cta: { label: t("Contact us"), to: "mailto:team@upscope.com" },
    };
  }

  if (currentRoute === "up.getting-started") return;

  if (purchaseRequiredTeam) {
    return {
      content: t("Your free trial has ended. Please purchase a subscription to continue using %{product}", {
        product: productName(purchaseRequiredTeam.products[0]!),
      }),
      cta: {
        label: t("Purchase"),
        to: ["up.onboarding.purchase", { teamDomain: purchaseRequiredTeam.domain }],
      },
    };
  }

  if (onTrialNoCardTeam) {
    return {
      content: t("Your free trial will end on %{formattedDate}. Add a card to continue using %{product}.", {
        product: productName(onTrialNoCardTeam.products[0]!),
        formattedDate: trialNoCardEndsOn!.toLocaleDateString(),
      }),
      cta: {
        label: t("Add payment method"),
        to: ["up.onboarding.purchase", { teamDomain: onTrialNoCardTeam.domain }],
      },
    };
  }
}
