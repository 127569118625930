import { useWindowSize } from "@react-hookz/web";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import { useLocation } from "react-router-dom";

const ConfettiContext = createContext({
  enabled: false,
  setEnabled: (enabled: boolean) => {},
});

export function ConfettiProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [enabled, setEnabled] = useState(false);
  return <ConfettiContext.Provider value={{ enabled, setEnabled }}>{children}</ConfettiContext.Provider>;
}

export function useConfetti() {
  const { setEnabled } = useContext(ConfettiContext);
  const triggerConfetti = useCallback(() => {
    setEnabled(true);
  }, [setEnabled]);
  return triggerConfetti;
}

export function Confetti() {
  const { enabled, setEnabled } = useContext(ConfettiContext);
  const { width, height } = useWindowSize();
  const location = useLocation();
  const [throwingConfetti, setThrowingConfetti] = useState(false);
  const onConfettiComplete = useCallback(() => {
    setEnabled(false);
  }, [setEnabled]);
  const clearing = enabled && !throwingConfetti;

  useEffect(() => {
    if (enabled) setThrowingConfetti(true);
  }, [enabled]);

  useEffect(() => {
    setThrowingConfetti(false);
  }, [location]);

  return (
    enabled && (
      <ReactConfetti
        run={enabled}
        width={width}
        height={height}
        numberOfPieces={60}
        gravity={clearing ? 0.5 : 0.04}
        className="!z-50"
        recycle={throwingConfetti}
        onConfettiComplete={onConfettiComplete}
      />
    )
  );
}
