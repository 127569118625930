import { useCallback } from "react";

export default function useGetSupport() {
  const getSupport = useCallback(() => {
    if (typeof (window as any).Intercom === "function") return (window as any).Intercom("showNewMessage");

    window.open("mailto:support@upscope.com");
  }, []);

  return { getSupport };
}
