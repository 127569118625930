import React, { useEffect, useState } from "react";

import { classNames } from "../../utils";

type Props<T extends string[]> = {
  options: Array<{
    id: T[number];
    content: React.ReactNode;
  }>;
  selected?: T;
  onChange?: (selected: T) => void;
  name?: string;
  multiple?: boolean;
  allowUnselect?: boolean;
  topOption?: T[number] | null;
  firstOptionSeparator?: React.ReactNode;
};

export default function VerticalOptions<T extends string[]>({
  options,
  onChange,
  multiple,
  allowUnselect,
  name,
  topOption,
  firstOptionSeparator,
  selected: propSelected,
}: Props<T>) {
  multiple = multiple ?? false;
  allowUnselect = allowUnselect ?? false;
  const [selected, setSelected] = useState<T>([] as any);
  const [changed, setChanged] = useState(false);

  function makeOnSelect(id: T[number]) {
    return (evt: React.MouseEvent) => {
      evt.preventDefault();
      setChanged(true);

      if (selected.includes(id)) {
        if (allowUnselect || selected.length > 1) setSelected(selected.filter((s) => s !== id) as T);
      } else if (multiple) {
        setSelected([...selected, id] as T);
      } else {
        setSelected([id] as T);
      }
    };
  }

  useEffect(() => {
    if (changed) onChange?.(selected);
  }, [selected, changed, onChange]);

  useEffect(() => {
    if (propSelected) setSelected(propSelected);
  }, [propSelected]);

  if (topOption) options = options.sort((a) => (a.id === topOption ? -1 : 1));

  return (
    <div className="space-y-3">
      <input type="hidden" name={name} value={selected.join(",")} />
      {options.map((option, index) => (
        <React.Fragment key={option.id}>
          <a
            onClick={makeOnSelect(option.id)}
            className={classNames(
              "flex text-sm",
              `block cursor-pointer rounded-lg border dark:border-slate-700 bg-white dark:bg-slate-800 px-4 py-3 shadow-sm
              focus:outline-none sm:flex sm:justify-between`,
              selected.includes(option.id) &&
                "border-primary dark:border-primary-100 ring-1 dark:ring-2 ring-primary dark:ring-primary-100",
            )}
          >
            {option.content}
          </a>
          {index === 0 && options.length > 0 && firstOptionSeparator}
        </React.Fragment>
      ))}
    </div>
  );
}

VerticalOptions.Icon = function Icon({ children }: { children: React.ReactNode }) {
  return <div className="flex shrink items-center pr-2">{children}</div>;
};

VerticalOptions.Left = function Left({ children }: { children: React.ReactNode }) {
  return <div className="grow">{children}</div>;
};
VerticalOptions.Right = function Right({ children }: { children: React.ReactNode }) {
  return <div className="text-right">{children}</div>;
};
VerticalOptions.Primary = function Secondary({ children }: { children: React.ReactNode }) {
  return <div className="font-semibold text-gray-800 dark:text-gray-200">{children}</div>;
};
VerticalOptions.Secondary = function Secondary({ children }: { children: React.ReactNode }) {
  return <div className="mt-1 font-normal text-gray-500">{children}</div>;
};
